@import url(https://fonts.googleapis.com/css2?family=Montserrat&family=Rubik&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
	box-sizing: border-box;
}

html,
body {
	height: 100%;
	margin: 0;
	-webkit-user-select: none;
	        user-select: none;
}

#root {
	height: inherit;
	background: #1e1e26;
	display: flex;
	flex-direction: column;
}

.App {
	height: 100%;
	flex: 1 1;
	display: flex;
	flex-direction: column;
}

.klever-tradingview-container {
	flex: 1 1;
	height: 100%;
}

