@import url('https://fonts.googleapis.com/css2?family=Montserrat&family=Rubik&display=swap');

* {
	box-sizing: border-box;
}

html,
body {
	height: 100%;
	margin: 0;
	user-select: none;
}

#root {
	height: inherit;
	background: #1e1e26;
	display: flex;
	flex-direction: column;
}

.App {
	height: 100%;
	flex: 1;
	display: flex;
	flex-direction: column;
}

.klever-tradingview-container {
	flex: 1;
	height: 100%;
}
